/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { formatDateTime, getTemperatureConversion } from "@roambee/client-utility";
// @ts-ignore
import { withTooltip } from "@roambee/client-styleguide";
// @ts-ignore
import { Tooltip } from "@mui/material";

const Asset_Table_Columns = [
	{
		accessorKey: "client_asset_number",
		Header: (
			<Tooltip title="Asset Number" placement="top">
				<div>Asset Number</div>
			</Tooltip>
		),
		header: "Asset Number",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "name",
		header: "Asset Name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "description",
		header: "Description",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "bee_name",
		Header: (
			<Tooltip title="Bee Name" placement="top">
				<div>Bee Name</div>
			</Tooltip>
		),
		header: "Bee Name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "device_imei",
		Header: (
			<Tooltip title="Device IMEI" placement="top">
				<div>Device IMEI</div>
			</Tooltip>
		),
		header: "Device IMEI",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "beezone_label",
		Header: (
			<Tooltip title="Beezone Label" placement="top">
				<div>Beezone Label</div>
			</Tooltip>
		),
		header: "Beezone Label",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "parent_bee",
		Header: (
			<Tooltip title="Latest Mapped Bee" placement="top">
				<div>Latest Mapped Bee</div>
			</Tooltip>
		),
		header: "Latest Mapped Bee",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "account_name",
		Header: (
			<Tooltip title="Account Name" placement="top">
				<div>Account Name</div>
			</Tooltip>
		),
		header: "Account Name",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "geo",
		id: "last_known_time",
		Header: (
			<Tooltip title="Last Known Time" placement="top">
				<div>Last Known Time</div>
			</Tooltip>
		),
		header: "Last Known Time",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => {
			return formatDateTime(cell.getValue() ? JSON.parse(cell.getValue()).last_known_time : null, "LLL dd, yyyy hh:mm a", cell.row?.original?.geo ? JSON.parse(cell.row?.original?.geo).timezone?.timezoneId : null);
		})
	},
	{
		accessorKey: "geo",
		id: "geo_address",
		Header: (
			<Tooltip title="Last Known Location" placement="top">
				<div>Last Known Location</div>
			</Tooltip>
		),
		header: "Last Known Location",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? JSON.parse(cell.getValue()).geo_address : "-"))
	},
	{
		accessorKey: "on_shipment",
		Header: (
			<Tooltip title="On Shipment" placement="top">
				<div>On Shipment</div>
			</Tooltip>
		),
		header: "On Shipment",
		isSortable: false,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue().charAt(0).toUpperCase() + cell.getValue().slice(1) : ""))
	},
	{
		accessorKey: "seen",
		header: "Seen",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "age.first_seen",
		header: "Age",
		isSortable: false,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => {
			return formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a");
		})
	},
	{
		accessorKey: "first_seen_time_stamp",
		Header: (
			<Tooltip title="Status Days" placement="top">
				<div>Status Days</div>
			</Tooltip>
		),
		header: "Status Days",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "shipment_uuid",
		Header: (
			<Tooltip title="Shipment ID" placement="top">
				<div>Shipment ID</div>
			</Tooltip>
		),
		header: "Shipment ID",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "created_date",
		Header: (
			<Tooltip title="Created Date" placement="top">
				<div>Created Date</div>
			</Tooltip>
		),
		header: "Created Date",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => {
			return formatDateTime(cell.getValue(), "LLL dd, yyyy hh:mm a");
		})
	},
	{
		accessorKey: "zone_count",
		Header: (
			<Tooltip title="Zone History" placement="top">
				<div>Zone History</div>
			</Tooltip>
		),
		header: "Zone History",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "dwell_time",
		Header: (
			<Tooltip title="Dwell Time" placement="top">
				<div>Dwell Time</div>
			</Tooltip>
		),
		header: "Dwell Time",
		isSortable: true,
		isSearchable: false,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.battery.remaining_days",
		Header: (
			<Tooltip title="Battery Days" placement="top">
				<div>Battery Days</div>
			</Tooltip>
		),
		header: "Battery Days",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.TMP.value",
		Header: (
			<Tooltip title="Temperature (°C)" placement="top">
				<div>Temperature (°C)</div>
			</Tooltip>
		),
		header: "Temperature (°C)",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => {
			const value = cell.getValue();
			return value ? getTemperatureConversion(value) : "-"; // Apply getTemperatureConversion
		})
	},
	{
		accessorKey: "sensor.ALT.value",
		Header: (
			<Tooltip title="Altitude (m)" placement="top">
				<div>Altitude (m)</div>
			</Tooltip>
		),
		header: "Altitude (m)",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.AMB.value",
		header: "Ambient",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.HUM.value",
		header: "Humidity",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.PRS.value",
		header: "Pressure",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "sensor.TLT.value",
		header: "Tilt",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
	},
	{
		accessorKey: "tor_status",
		header: "TOR Status",
		isSortable: true,
		isSearchable: true,
		Cell: withTooltip(({ cell }) => (cell.getValue() === 0 ? "false" : cell.getValue() === 1 ? "true" : "NA"))
	}
];

const Asset_Table_Filter_Operations = {
	client_asset_number: "has",
	name: "has",
	description: "has",
	bee_name: "has",
	device_imei: "has",
	beezone_label: "has",
	parent_bee: "has",
	account_name: "has",
	"geo.last_known_time": "has",
	"geo.geo_address": "has",
	on_shipment: "has",
	seen: "has",
	"age.first_seen": "has",
	first_seen_time_stamp: "has",
	shipment_uuid: "has",
	custom_fields: "has",
	created_date: "has",
	zone_count: "has",
	dwell_time: "has",
	"sensor.battery.remaining_days": "has",
	"sensor.TMP.value": "has",
	"sensor.ALT.value": "has",
	"sensor.AMB.value": "has",
	"sensor.HUM.value": "has",
	"sensor.PRS.value": "has",
	"sensor.TLT.value": "has",
	tor_status: "eq",
	asset_type: "eq",
	active_status: "eq",
	field_not_exists: "eq",
	field_exists: "eq"
};

export { Asset_Table_Columns, Asset_Table_Filter_Operations };
