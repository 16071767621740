/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useState } from "react";
// @ts-ignore
import { DataTable, DrawerPanel, Loader, SubHeader, withTooltip, IconButton } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser, formatCustomDateTime } from "@roambee/client-utility";
import { Asset_Table_Columns, Asset_Table_Filter_Operations } from "../configs/AssetTableConfig";
import { API_PATH, URL_PATH } from "../configs/AssetConfig";
import { useSearchParams } from "react-router-dom";
import { Download, CopyLink, Checkmark, Result, Edit, ChartLine, TrashCan, OverflowMenuVertical, Settings } from "@carbon/icons-react";
import { Tooltip, Box, MenuItem, ListItemIcon, ListItemText, Menu } from "@mui/material";

let tableConfiguration: any = localStorage.getItem("asset_table");
if (tableConfiguration && tableConfiguration.length) {
	tableConfiguration = JSON.parse(tableConfiguration);
}

const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString.split(" and ").map((filter) => {
			if (filter) {
				const [id, operation, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
				return { id, value };
			}
		});
	} else {
		return null;
	}
};

function AssetTable() {
	const user = getAuthUser();
	let urlFilters: any = {};
	const [searchParams, setSearchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		try {
			urlFilters = JSON.parse(decodeURIComponent(searchParams.get("filter") || ""));
			console.info(urlFilters, "Parsed URL Filters");
		} catch (e) {
			console.error("Failed to parse JSON:", e);
		}
	}
	const [data, setData] = useState({ data: [], page_num: 1, page_size: 10, statusCode: 0, total: 0 });
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState("all");
	const [columnFilters, setColumnFilters] = useState(
		parseFilterString(urlFilters?.filter) ||
			parseFilterString(tableConfiguration?.filter) || [
				{ id: "asset_type", value: "0" },
				{ id: "active_status", value: "1" }
			]
	);
	const [columnFilterModes, setColumnsFilterModes] = useState([]);
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset || tableConfiguration?.offset || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});
	const [columnPinning, setColumnPinning] = useState<any>(urlFilters?.columnPinning || tableConfiguration?.columnPinning || { left: [], right: ["actions"] });
	const [columnSizing, setColumnSizing] = useState<any>(urlFilters?.columnSizing || tableConfiguration?.columnSizing || {});
	const [columnOrdering, setColumnOrdering] = useState<any>(urlFilters?.columnOrdering || tableConfiguration?.columnOrdering || []);
	const [density, setDensity] = useState<any>(urlFilters?.density || tableConfiguration?.density || "comfortable");
	const [columnVisibility, setColumnVisibility] = useState<any>(urlFilters?.columnVisibility || tableConfiguration?.columnVisibility || {});
	const [anchorEl, setAnchorEl] = useState(null);
	const [openRowId, setOpenRowId] = useState(null);

	const actionItemConfigs = useMemo(
		() => [
			{
				label: "Details",
				disabled: false,
				icon: <Result />,
				onClick: (row) => onDetails(row)
			},
			{
				label: "Edit",
				disabled: true,
				icon: <Edit />,
				onClick: () => onAssetUpdate()
			},
			{
				label: "Delete",
				disabled: true,
				icon: <TrashCan />,
				onClick: () => onAssetDelete()
			},
			{
				label: "Zone Movement Insight",
				disabled: false,
				icon: <ChartLine />,
				onClick: (row) => redirectAssetExplain(row)
			}
		],
		[]
	);

	const [headerButtonConfigs, setHeaderButtonConfigs] = useState([
		{
			tooltipText: "Copy Deep-Link",
			variant: "no-bg",
			size: "small",
			icon: <CopyLink />,
			onClick: () => handleCopyDeepLink()
		},
		{
			tooltipText: "Download Report",
			variant: "no-bg",
			size: "small",
			icon: <Download />,
			onClick: () => handleDownloadReport()
		}
	]);

	const handleFilterClick = useCallback(
		(tab) => {
			// Update columnFilters based on tab
			if (tab === "all") {
				// Remove filter with id: "tor_status"
				setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "tor_status" && filter.id !== "field_exists" && filter.id !== "field_not_exists"));
			} else if (tab === "tor") {
				// Check if there is already a filter with id: "tor_status"
				const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "tor_status");

				if (existingFilterIndex !== -1) {
					// Update existing filter with id: "tor_status"
					const updatedColumnFilters = [...columnFilters];
					updatedColumnFilters[existingFilterIndex].value = tab === "tor" ? "1" : "0";
					setColumnFilters(updatedColumnFilters);
				} else {
					// Add new filter with id: "tor_status"
					const updatedColumnFilters = [...columnFilters];
					const tempFilters = updatedColumnFilters.filter((filter) => filter.id !== "field_exists" && filter.id !== "field_not_exists");
					setColumnFilters([...tempFilters, { id: "tor_status", value: tab === "tor" ? "1" : "0" }]);
				}
			} else if (tab === "paired") {
				// Check if there is already a filter with id: "field_exists"
				const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "field_exists");

				if (existingFilterIndex !== -1) {
					// Update existing filter with id: "field_exists"
					const updatedColumnFilters = [...columnFilters];
					updatedColumnFilters[existingFilterIndex].value = "device_imei";
					setColumnFilters(updatedColumnFilters);
				} else {
					// Add new filter with id: "field_exists"
					const updatedColumnFilters = [...columnFilters];
					const tempFilters = updatedColumnFilters.filter((filter) => filter.id !== "tor_status" && filter.id !== "field_not_exists");
					setColumnFilters([...tempFilters, { id: "field_exists", value: "device_imei" }]);
				}
			} else if (tab === "unpaired") {
				// Check if there is already a filter with id: "field_not_exists"
				const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "field_not_exists");

				if (existingFilterIndex !== -1) {
					// Update existing filter with id: "field_not_exists"
					const updatedColumnFilters = [...columnFilters];
					updatedColumnFilters[existingFilterIndex].value = "device_imei";
					setColumnFilters(updatedColumnFilters);
				} else {
					// Add new filter with id: "field_not_exists"
					const updatedColumnFilters = [...columnFilters];
					const tempFilters = updatedColumnFilters.filter((filter) => filter.id !== "tor_status" && filter.id !== "field_exists");
					setColumnFilters([...tempFilters, { id: "field_not_exists", value: "device_imei" }]);
				}
			}
		},
		[columnFilters, setColumnFilters]
	);

	const addParamToUrl = useCallback(
		(key, value) => {
			searchParams.set(key, value);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const removeParamFromUrl = useCallback(
		(key) => {
			searchParams.delete(key);
			setSearchParams(searchParams);
		},
		[searchParams, setSearchParams]
	);

	const handleTabChange = useCallback(
		(key) => {
			if (key == "all") {
				setCurrentTab("all");
				removeParamFromUrl("activeTab");
				handleFilterClick("all");
				localStorage.removeItem("asset_activeTab");
			} else {
				setCurrentTab(key);
				addParamToUrl("activeTab", key);
				handleFilterClick(key);
				localStorage.setItem("asset_activeTab", key);
			}
		},
		[handleFilterClick, addParamToUrl, removeParamFromUrl]
	);

	const tabConfigs = useMemo(
		() => [
			{
				label: "All",
				active: currentTab === "all",
				onClick: () => {
					if (currentTab !== "all") {
						handleTabChange("all");
					}
				}
			},
			// {
			// 	label: "Paired",
			// 	active: currentTab === "paired",
			// 	onClick: () => {
			// 		if (currentTab !== "paired") {
			// 			handleTabChange("paired");
			// 		}
			// 	}
			// },
			// {
			// 	label: "Not Paired",
			// 	active: currentTab === "unpaired",
			// 	onClick: () => {
			// 		if (currentTab !== "unpaired") {
			// 			handleTabChange("unpaired");
			// 		}
			// 	}
			// },
			{
				label: "Active TOR",
				active: currentTab === "tor",
				onClick: () => {
					if (currentTab !== "tor") {
						handleTabChange("tor");
					}
				}
			}
		],
		[currentTab, handleTabChange]
	);

	const handleBulkDownload = useCallback(() => {
		const BASE_URL = process.env.CLIENT_HC10_API_URL;
		const downloadParams = {
			selector: {
				filters: [{ type: "EQUAL", items: [{ name: "asset_type", value: "0" }] }],
				pagination: { page_size: pagination.pageSize, page_num: pagination.pageIndex + 1 }
			},
			asset_type: "asset"
		};
		const queryParams = generateQueryParams(downloadParams);
		window.open(BASE_URL + URL_PATH.BULK_DOWNLOAD + queryParams);
	}, [pagination]); // Add pagination as a dependency

	const buttonConfigs = useMemo(
		() => [
			{
				label: "Bulk Download",
				variant: "primary",
				size: "small",
				disabled: false,
				onClick: () => handleBulkDownload()
			},
			{
				label: "Add Inventory",
				variant: "primary",
				size: "small",
				disabled: true,
				onClick: () => console.info("Add Inventory Button Clicked")
			}
		],
		[handleBulkDownload]
	);

	const customAssetFields = user?.account?.data?.custom_asset_fields || [];
	const customFieldColumns = customAssetFields.map((element) => {
		if (element.entity_type === "asset") {
			if (element.label.toLowerCase().includes("date")) {
				return {
					accessorKey: `custom_fields.${element.label.toLowerCase()}`,
					header: element.label,
					Header: (
						<Tooltip title={element.label}>
							<div>{element.label}</div>
						</Tooltip>
					),
					isSortable: true,
					isSearchable: false,
					Cell: withTooltip(({ cell }) => formatCustomDateTime(cell.getValue() ? cell.getValue()[0] : null, "LLL dd, yyyy hh:mm"))
				};
			} else {
				return {
					accessorKey: `custom_fields.${element.label.toLowerCase()}`,
					header: element.label,
					Header: (
						<Tooltip title={element.label}>
							<div>{element.label}</div>
						</Tooltip>
					),
					isSortable: true,
					isSearchable: false,
					Cell: withTooltip(({ cell }) => (cell.getValue() ? cell.getValue() : "-")) // Ensure a return value
				};
			}
		}
	});

	const handleClick = (event, rowId) => {
		setAnchorEl(event.currentTarget);
		setOpenRowId(rowId);
	};

	const handleActionMenuClose = () => {
		setAnchorEl(null);
		setOpenRowId(null);
	};

	const handleAssetConfigure = () => {
		console.info("Asset Configure Clicked!");
	};

	const actionColumn = useMemo(() => {
		return {
			id: "actions",
			header: "Actions",
			Header: (
				<Tooltip title="Actions">
					<div>
						<IconButton ariaLabel="Actions" icon={<Settings />} disabled={true} size="small" variant="no-bg" onClick={() => handleAssetConfigure()} />
					</div>
				</Tooltip>
			),
			size: 60,
			columnDefType: "display",
			enablePinning: false,
			Cell: ({ row }) => {
				const isOpen = openRowId === row.id;
				return (
					<>
						<Box>
							<IconButton ariaLabel="Row actions" icon={<OverflowMenuVertical />} size="small" variant="no-bg" aria-controls={isOpen ? "actions-menu" : undefined} aria-haspopup="true" aria-expanded={isOpen ? "true" : undefined} onClick={(e) => handleClick(e, row.id)} />
						</Box>
						<Menu anchorEl={anchorEl} id="datable-Menu" open={isOpen} onClose={handleActionMenuClose} onClick={handleActionMenuClose} transformOrigin={{ horizontal: "right", vertical: "top" }} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
							{actionItemConfigs.map((actionMenuItem) => (
								<MenuItem key={actionMenuItem.label} onClick={() => actionMenuItem.onClick(row)} disabled={actionMenuItem.disabled}>
									<ListItemIcon>{actionMenuItem.icon}</ListItemIcon>
									<ListItemText primary={actionMenuItem.label} />
								</MenuItem>
							))}
						</Menu>
					</>
				);
			}
		};
	}, [anchorEl, openRowId, actionItemConfigs]);
	const columns = useMemo(() => {
		if (customFieldColumns.includes(undefined)) {
			const customFields = customFieldColumns.filter((col) => col !== undefined);
			return [...Asset_Table_Columns, ...customFields, actionColumn];
		} else {
			return [...Asset_Table_Columns, ...customFieldColumns, actionColumn];
		}
	}, [customFieldColumns, actionColumn]);

	useEffect(() => {
		setLoading(true);
		const activeTab = localStorage.getItem("asset_activeTab") || null;
		const urlActiveTab = searchParams.get("activeTab") || null;
		if (activeTab) {
			if (activeTab == "tor") {
				setCurrentTab("tor");
				addParamToUrl("activeTab", "tor");
			} else if (activeTab == "paired") {
				setCurrentTab("paired");
				addParamToUrl("activeTab", "paired");
			} else if (activeTab == "unpaired") {
				setCurrentTab("unpaired");
				addParamToUrl("activeTab", "unpaired");
			}
		} else if (urlActiveTab) {
			if (urlActiveTab == "tor") {
				setCurrentTab("tor");
				localStorage.setItem("asset_activeTab", "tor");
			} else if (urlActiveTab == "paired") {
				setCurrentTab("paired");
				localStorage.setItem("asset_activeTab", "paired");
			} else if (urlActiveTab == "unpaired") {
				setCurrentTab("unpaired");
				localStorage.setItem("asset_activeTab", "unpaired");
			}
		} else {
			setCurrentTab("all");
		}
		const params = {
			offset: pagination.pageIndex || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: generateFilterString(columnFilters),
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.ASSETS}?${queryString}`)
			.then((result) => {
				setData(result);
				setLoading(false);
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					// eslint-disable-next-line no-console
					console.error(error.message);
				} else {
					// eslint-disable-next-line no-console
					console.error("Error:", error);
					EventEmitter.emit("showSnackbar", {
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
				}
				setLoading(false);
			});
		localStorage.setItem("asset_table", JSON.stringify(params));
	}, [columnFilters, pagination, sorting, searchParams]);

	useEffect(() => {
		// Fetch the previous state from local storage
		const getsavedTableconfig = JSON.parse(localStorage.getItem("asset_table")) || {};
		const updatedTableConfig = {
			...getsavedTableconfig,
			columnPinning,
			columnSizing,
			columnOrdering,
			density,
			columnVisibility
		};
		// Save the updated state to local storage
		localStorage.setItem("asset_table", JSON.stringify(updatedTableConfig));
	}, [columnPinning, columnSizing, columnOrdering, density, columnVisibility]);

	const generateFilterString = (filters) => {
		const filterString = filters
			.filter((filter) => filter.value !== null && filter.value !== undefined && filter.value.length !== 0)
			.map((filter) => {
				const operation = Asset_Table_Filter_Operations[filter.id];
				if (!operation) return "";

				return `${filter.id} ${operation} '${filter.value}'`;
			})
			.filter((filter) => filter !== "")
			.join(" and ");

		return filterString;
	};

	const handleCopyDeepLink = () => {
		navigator.clipboard.writeText(window.location.origin + window.location.pathname + `?filter=${localStorage.getItem("asset_table")}${localStorage.getItem("asset_activeTab") ? "&activeTab=" + localStorage.getItem("asset_activeTab") : ""}`);

		setHeaderButtonConfigs((prevConfigs) => {
			const updatedConfigs = [...prevConfigs];
			updatedConfigs.splice(0, 1, {
				tooltipText: "Link Copied!",
				variant: "no-bg",
				size: "small",
				icon: <Checkmark />,
				onClick: () => console.info("Link Copied!")
			});
			return updatedConfigs;
		});

		setTimeout(() => {
			setHeaderButtonConfigs((prevConfigs) => {
				const headerConfigs = [...prevConfigs];
				headerConfigs.splice(0, 1, {
					tooltipText: "Copy Deep-Link",
					variant: "no-bg",
					size: "small",
					icon: <CopyLink />,
					onClick: () => handleCopyDeepLink()
				});
				return headerConfigs;
			});
		}, 3500);
	};

	const handleDownloadReport = () => {
		const BASE_URL = process.env.CLIENT_HC10_API_URL;
		const downloadParams = {
			selector: {
				filters: [{ type: "EQUAL", items: [{ name: "asset_type", value: "0" }] }],
				pagination: { page_size: pagination.pageSize, page_num: pagination.pageIndex + 1 }
			},
			export: "csv"
		};
		const queryParams = generateQueryParams(downloadParams);
		window.open(BASE_URL + API_PATH.V1_ASSETS + queryParams);
	};

	const redirectAssetExplain = (row) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		const url = URL_PATH.ZONE_MOVEMENT + "?asset_uuid=" + row.original.asset_uuid + "&imei=" + row.original.device_imei + "&name=" + row.original.name + "&inventory_name=" + row.original.client_asset_number;
		window.open(BASE_URL + url);
	};

	const onDetails = (asset) => {
		const BASE_URL = process.env.CLIENT_HC10_URL;
		window.open(BASE_URL + URL_PATH.ASSETS + asset.original.asset_uuid);
	};

	const onAssetUpdate = () => {
		console.info("Edit Asset From Menu Clicked!;");
	};

	const onAssetDelete = () => {
		console.info("Delete Asset From Menu Clicked!;");
	};

	return (
		<div>
			{loading && <Loader />}
			<SubHeader tabConfigs={tabConfigs} buttonConfigs={buttonConfigs} currentTab={currentTab} onTab={setCurrentTab} />
			<DataTable title="Assets" columns={columns} data={data?.data || []} rowCount={data?.total} isLoading={loading} columnFilters={columnFilters} onColumnFiltersChange={setColumnFilters} columnFilterFns={columnFilterModes} onColumnFilterFnsChange={setColumnsFilterModes} sorting={sorting} onSortingChange={setSorting} pagination={pagination} onPaginationChange={setPagination} headerButtonConfigs={headerButtonConfigs} onColumnSizingChange={setColumnSizing} columnSizing={columnSizing} onColumnOrderChange={setColumnOrdering} columnOrder={columnOrdering} density={density} onDensityChange={setDensity} onColumnVisibilityChange={setColumnVisibility} columnVisibility={columnVisibility} columnPinning={columnPinning} onColumnPinningChange={setColumnPinning} />
			<DrawerPanel open={open} setOpen={setOpen} title="Filter">
				All Filters Here
			</DrawerPanel>
		</div>
	);
}

export default AssetTable;
